import React from "react";

export default function btm_1() {
  return (
    <>
      <div id="howWork" className="column">
        <div className="heading">
          <h2>How it works?</h2>
        </div>
        <div className="how_weWork row">
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon1.png"
                  width="50"
                  height="50"
                  alt="Pre-Move Survey"
                />
              </div>
              <div className="text">
                <h4>Pre-Move Survey</h4>
                <p>
                  Discuss with movers for Shifting Process, Quotation, and
                  Additional Services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon2.png"
                  width="50"
                  height="50"
                  alt="Dis-Assembling"
                />
              </div>
              <div className="text">
                <h4>Dis-Assembling</h4>
                <p>
                  Trained performers prepared to disassemble the makeshift
                  venue,load them onto the trucks.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon3.png"
                  width="50"
                  height="50"
                  alt="Quality Packaging"
                />
              </div>
              <div className="text">
                <h4>Quality Packaging</h4>
                <p>
                  Best Range of Poly & Paper based Packaging Products that are
                  used in Shifting.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon4.png"
                  width="50"
                  height="50"
                  alt="Careful Loading<"
                />
              </div>
              <div className="text">
                <h4>Careful Loading</h4>
                <p>
                  Loading-Carefully and arrange the items on Moving Truck or
                  Lorry to reach safely their destination.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon5.png"
                  width="50"
                  height="50"
                  alt="Secure Transport"
                />
              </div>
              <div className="text">
                <h4>Secure Transport</h4>
                <p>
                  All the item is moved to the required destination with safety
                  and security
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon6.png"
                  width="50"
                  height="50"
                  alt="Keeping at Warehouse"
                />
              </div>
              <div className="text">
                <h4>Keeping at Warehouse</h4>
                <p>
                  we are one stop storage providers to store your valuable
                  possessions.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon7.png"
                  width="50"
                  height="50"
                  alt="on time delivery"
                />
              </div>
              <div className="text">
                <h4>On-time Delivery</h4>
                <p>
                  On-time delivery enable our users to experience a smooth
                  relocation.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="item">
              <div className="icon">
                <img
                  src="../images/hiw-icon8.png"
                  width="50"
                  height="50"
                  alt="unloading"
                />
              </div>
              <div className="text">
                <h4>Careful Un-loading</h4>
                <p>
                  Safely removing secured items during unloading at the final
                  destination.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
