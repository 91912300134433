import React from "react";

export default function btm_1() {
  return (
    <>
      <section className="inner_banner text-white" style={{backgroundImage: 'url(../images/form-bg.jpg)'}}>
        <div className="container">
            <div className="heading text-center"><h1>About Us</h1></div>
        </div>
    </section>
    <div className="about_wraper pt-1 pb-5">
        <div className="container">
            <div className="content column_box">
                <div className="heading">
        			<h1>Best Movers and Packers India</h1>
        		</div>
                
				<p style={{textalign:'justify'}}>EasyMyShift is one of the best packers and movers in India. It is another successful origin from the Easy My Shift, and it is the most trusted and verified Movers and Packers India. We are popular for our high-quality domestic relocation services. which will provide you with a complete relocation experience. Whether you are moving household items, office furniture or entrusted items, car and bike transportation&nbsp;you can safely transport the goods to the desired location by relying on us.</p>
				SS<p style={{textalign:'justify'}}>Our team of professional packers and movers are highly trained and efficient in dismantling &ndash; reassembling, loading, and unloading heavy items. They are skilled in handling any type of odd-shaped and bulky items. With us, you can rest assured of a safe and trouble-free moving experience.</p>
				<p style={{textalign:'justify'}}>We are experienced with more than <strong>15 years in this service industry</strong> and we happily shifted more than 9000 customers to their desired places all over India.&nbsp;&nbsp;We have a <strong>24x7 customer support service</strong> to help you out anytime in the day or even at night.</p>
				<p style={{textalign:'justify'}}>We use the best material quality and special care processes to package goods: from furniture, books, and decorative pieces to pottery and glassware. The place of origin is removed and installed in your new house, including our service of fixing the furniture to the wall, so you don&rsquo;t worry and do not put your extra effort.</p>
				<p style={{textalign:'justify'}}>Also, we try to keep our charges affordable so that our customers can avail of our quality services to relocate their household or commercial goods from anywhere&nbsp;in India. So you can shift hassle-free to your dream home at the lowest cost and highest satisfaction.</p>
				<p style={{textalign:'justify'}}>Choose us as a Trusted and <strong>Best Movers and Packers in India</strong>.</p>
				
				<h2>Why Choose Us</h2>
				<p>Pack for a move is very common and it can be one of the most stressful aspects of planning for Moving stuff or packing and unpacking of goods in the most efficient manner, By making the use of the most durable and strong packaging material, the safety is must.</p>
				<p>Shifting to a new place is always a big challenge. The process of packing up, relocating to a new city and unpacking is anything but easy. So that hiring packer and mover is a viable and effective alternative to doing it yourself and ensure that all your belongings are safe.</p>
				<h4>There are top 7 benefits of hiring professional packers and movers:-</h4>
				<p>1. Efficient and safe moving—There are many Benefits of hiring  packers and movers more than the move your belongings. The services which  they  provide will make sure that your furniture, appliances, decorative items, etc are safe. They use high quality materials like cartoons, appropriate cushioning and soft wrapping polythene for packing. They pack and transport all your delicate items with extra care which is most important part of safe moving. Once reach  your destination, the crew will unload all your items and help you unpack. You can get your heavy furniture and large appliances set up right away and not need to worry about hiring more helper.</p>
				<p>2. Fast services with loading and unloading process-packers and movers in jaipur rajasthan are experts and dedicated to provide worldwide quality packing and moving solution for each and every type of requirements. We take extreme care to shift your vehicle from source to destination, Other household or office goods, your cares transported separately in covered containers, under our more precise care. </p>
				<p>3. Offer transit insurance-Easy My shift (Packing and moving services in jaipur) is known for providing quality relocation services and customer satisfaction every time. Including packers and mover’s service, we also offer car carrier service, loading and unloading service, warehouse service, and insurance service in rajasthan.we provide insurance for household goods or official goods services. Except few factors beyond our control like accident, fire, sabotage, riots etc. We provide you with insurance for damaged and lost goods. we give you an extra level of security for shifting your products over long distances,Moreover, it is an essential aspect of expensive shifting.</p>
				<p>4 .It is budget-friendly- we as an expert team handle your precious stuff shifting professionally and provide you best and affordable home shifting at affordable price.</p>
				<p>5. Warehousing- This is for different city if you wish for home reloation. Usually, such a move means that there is a time gap between when you physically leave your current house and move in to your new address in the new city. If you were to move your stuff by yourself, arranging for warehousing services can be quite a hassle. Safety is the other concern too. With professional packers and movers, all your goods will be packed into a single container that can be off-loaded and attached to a different truck. We will have our own warehousing arrangements where the container (and your belongings) will be stored safely until you are ready to receive it.</p>
				<p>6. Door to door shifting service- This process starts from the dispatch of products from your warehouse to the delivery of the product to the end customer everything is done at one go, therefore you do not need to arrange for any first- mile or last-mile delivery separately.</p>
				<p>7. Trained workers-We have trained workers who do all operations from loading to unloading, from packing to unpacking that are required for hassle-free shifting, we also provide you with trained workers for your shifting. You can contact them at any point and understand the status of your shipments or clarify and doubts that you may have for packing the products for dispatch.</p>
				
				<h2>How We Work?</h2>
				<h4>We follow budget-friendly and hassle free process to deliver services after receive customer order.</h4>
				<ul>
					<li>you will get call from our managing team to tell you about the shifting process, After booking,</li>
					<li>Our shifting manager will reach at your location with the packaging trained team on scheduled date and time</li>
					<li>The team member will pack your stuff by following appropriate safety protocols and ease you do not to worry.</li>
					<li>Shifting manager will mark the items.</li>
					<li>Shifting manager will prepare the documents along with item list which need to move</li>
					<li>After documentation, loading will begin</li>
					<li>Floor staff will handover a copy of the documents to you</li>
					<li>Items will at reach your destination within the time slot given by you</li>
					<li>Unloading team will reach along with your Stuff and deliver your goods safely at your home or office</li>
				</ul>
				<p>Choose EasyMyShift packers and movers for simple, effortless and hassle free shifting and moving all over Rajasthan.</p>
            </div>
            
        	<section className="learnerSupport destinations column_box mt-4">
        		<div className="column">
        			<div className="heading text-center">
        				<span className="subTitle">For queries, feedback &amp; assistance</span>
        				<h2>EasyMyShift Helpline Support</h2>
        				<p>(7 AM - 9 PM)</p>
        			</div>
        			<div className="row justify-content-center num_detail">
        				<div className="col-md-5">
        					<div className="box text-center">
        						<h3>For Domestic/Commercial Moving</h3>
        						<a className="btn theme-btn" href="tel:9812009192"><i className="fa fa-phone"></i> +91 9812009192</a>
        					</div>
        				</div>
        				<div className="col-md-5">
        					<div className="box text-center">
        						<h3>For Bike/Car Transport</h3>
        						<a className="btn theme-btn" href="tel:9812009192"><i className="fa fa-phone"></i> +91 9812009192</a>
        					</div>
        				</div>
        			</div>
        		</div>
        	</section> 
        </div>
    </div>
    </>
  );
}
