import React, { useState } from "react";  // Combined the import statements
import { Link } from "react-router-dom";
import "./suggestions.css";
import { Helmet } from "react-helmet";

export default function btm_1() {
    function MyForm() {
        const [name, setName] = useState('');
        const [number, setNumber] = useState('');
        const [email, setEmail] = useState('');
        const [movingFrom, setMovingFrom] = useState('');
        const [movingTo, setMovingTo] = useState('');
        const [movingDate, setMovingDate] = useState('');
        const [movingDetail, setMovingDetail] = useState('');

        const [suggestionsFrom, setSuggestionsFrom] = useState([]);
        const [suggestionsTo, setSuggestionsTo] = useState([]);
        const [error, setError] = useState('');
        const [step, setStep] = useState(1); // Step control state

        const fetchSuggestions = async (query, setSuggestions) => {
            if (query.length > 2) {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?city=${query}&countrycodes=IN&format=json&addressdetails=1&limit=5`
                );
                const data = await response.json();
                setSuggestions(data.map((item) => item.display_name));
            } else {
                setSuggestions([]);
            }
        };

        const handleInputChange = (value, setValue, setSuggestions) => {
            setValue(value);
            fetchSuggestions(value, setSuggestions);
        };
        // const handleInputChange2 = (value) => {
        //     setValue(value);
        // };
        const removeHindiWords = (text) => {
            const hindiRegex = /[\u0900-\u097F]/g;
            let cleanedText = text.replace(hindiRegex, '').trim();
            cleanedText = cleanedText
                .split(',')
                .map(item => item.trim())
                .filter(item => item !== '')
                .join(', ');
            return cleanedText;
        };

        const handleSuggestionClick = (suggestion, setValue, setSuggestions) => {
            const cleanedSuggestion = removeHindiWords(suggestion);
            setValue(cleanedSuggestion);
            setSuggestions([]);
        };


        //==============================================================================
        const handleNextClick = (event) => {
            event.preventDefault();
            if (movingFrom === '' || movingTo === '') {
                setError("Please fill in both 'Shifting From' and 'Shifting To'");
            } else {
                setError('');
                setStep(2); // Move to second step
            }
        };

        const handleSubmitClick = (event) => {
            event.preventDefault();
            if (name === '' || number === '') {
                setError("Please fill in both 'Your Name' and 'Contact No.'");
            } else {
                setError('');
                // Submit form logic here
            }
        };

        const handleBackClick = (e) => {
            e.preventDefault();
            setStep(1);  // Switch back to step 1
        };

        return (
            <>
            <Helmet>
				<title>Welcome to EasyMyShift - Packers and Movers in India</title>
				<meta name="description" content="Looking for reliable packers and movers in India? EasyMyShift offers convenient, affordable, and reliable relocation services for household items, vehicles, and more." />
			</Helmet>
                <section className="heroBanner" >
                    <div className="heroBg_img homeBg_img active" style={{ backgroundimage: 'url(images/form_back.jpg)' }}></div>
                    <div className="heroBg_img officeBg_img" style={{ backgroundimage: 'url(images/form_back.jpg)' }}></div>
                    <div className="heroBg_img carBg_img" style={{ backgroundimage: 'url(images/form_back.jpg)' }}></div>
                    <div className="heroBg_img bikeBg_img" style={{ backgroundimage: 'url(images/form_back.jpg)' }}></div>
                    <div className="form_wraper">
                        <div className="container">
                            <h1 className="text-center">Easy My Shift for Best Packing and Shifting Services</h1>
                            <div className="formBox">
                                <ul className="searchTab nav" role="tablist">
                                    <li className="nav-item"><a className="homeTab nav-link active" data-toggle="tab" href="#searchHome">Get Free Quotation</a></li>
                                </ul>
                                <div className="searchBar tab-content">
                                    <div id="searchHome" className="tab-pane show active">


                                        <form className="searchForm pb-4" style={{ backgroundColor: "#E1E9ED" }}  method="POST" encType="multipart/form-data" action="https://formsubmit.co/3ce499c88c3b70c3f74752c5d59fd841">
                                            <span style={{ backgroundColor: "red", color: "#fff", padding: "0px 5px", display: error ? "inline" : "none" }} id="error_status">{error}</span>

                                            {/* First Step */}
                                            {step === 1 && (
                                                <div className="row pt-4 pl-3 pr-3" id="first_step">
                                                    <div className="col-lg-3 pb-2">
                                                        <strong>Shifting From *</strong>
                                                        <input
                                                            type="text"
                                                            id="shifting_from"
                                                            name="shifting_from"
                                                            className="form-control"
                                                            placeholder="Shifting From *"
                                                            value={movingFrom}
                                                            onChange={(e) => handleInputChange(e.target.value, setMovingFrom, setSuggestionsFrom)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 pb-2">
                                                        <strong>Shifting To *</strong>
                                                        <input
                                                            type="text"
                                                            id="shifting_to"
                                                            name="shifting_to"
                                                            className="form-control"
                                                            placeholder="Shifting To *"
                                                            value={movingTo}
                                                            onChange={(e) => handleInputChange(e.target.value, setMovingTo, setSuggestionsTo)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 pb-2">
                                                        <strong>Shifting Type *</strong>
                                                        <select className="form-control" id="shifting_type" name="shifting_type">
                                                            <option>Household Shifting</option>
                                                            <option>Household + Car Shifting</option>
                                                            <option>Household + Bike Shifting</option>
                                                            <option>Few Household Item Shifting</option>
                                                            <option>Car Shifting</option>
                                                            <option>SUV Car Shifting</option>
                                                            <option>Bike Shifting</option>
                                                            <option>Office Shifting</option>
                                                            <option>Single Item Shifting</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-3 pb-2">
                                                        <br />
                                                        <button type="submit" id="next_btn" className="btn btn-danger btn-md col-md" onClick={handleNextClick}>
                                                            Next
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            {/* Second Step */}
                                            {step === 2 && (
                                                <div className="row pt-4 pl-3 pr-3" id="second_step">
                                                    <div className="col-lg-3 pb-2">
                                                        <strong>Your Name *</strong>
                                                        <input
                                                            type="text"
                                                            id="client_name"
                                                            name="client_name"
                                                            className="form-control"
                                                            placeholder="Your Name *"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 pb-2">
                                                        <strong>Contact No. *</strong>
                                                        <input
                                                            type="text"
                                                            id="contact_number"
                                                            name="contact_number"
                                                            className="form-control"
                                                            placeholder="Contact No. *"
                                                            value={number}
                                                            onChange={(e) => setNumber(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 pb-2">
                                                        <strong>Email Address</strong>
                                                        <input
                                                            type="email"
                                                            id="email_address"
                                                            name="email_address"
                                                            className="form-control"
                                                            placeholder="Email Address"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="col-lg-3 pb-2">
                                                        <div><span id="back_btn" style={{background:"#EB8102",color:"#fff", padding: "0px 5px", cursor:"pointer"}} onClick={handleBackClick}>Back</span></div>
                                                        <button type="submit" id="submit_details" className="btn btn-danger btn-md col-md">
                                                            Submit Details
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </form>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>















                <section className="statsWrapper">
                    <div className="container">
                        <div className="processItem row">
                            <div className="item col-6 col-md-4 col-lg-2">
                                <div className="stat__icon"><Link to="home-domesting-shifting"><img src="images/se-1.png" width="55" height="55" alt="home shifting" loading="lazy" /></Link></div>
                                <div className="box">
                                    <h3><Link to="home-domesting-shifting">House Shifting</Link></h3>
                                </div>
                            </div>
                            <div className="item col-6 col-md-4 col-lg-2">
                                <div className="stat__icon"><Link to="office-commercial-shifting"><img src="images/se-2.png" width="55" height="55" alt="Office Shifting" loading="lazy" /></Link></div>
                                <div className="box">
                                    <h3><Link to="office-commercial-shifting">Office Shifting</Link></h3>
                                </div>
                            </div>
                            <div className="item col-6 col-md-4 col-lg-2">
                                <div className="stat__icon"><Link to="bike-transportation"><img src="images/se-3.png" width="55" height="55" alt="jobs" loading="lazy" /></Link></div>
                                <div className="box">
                                    <h3><Link to="bike-transportation">Bike Transportation</Link></h3>
                                </div>
                            </div>
                            <div className="item col-6 col-md-4 col-lg-2">
                                <div className="stat__icon"><Link to="car-transportation"><img src="images/se-4.png" width="55" height="55" alt="car transportation" loading="lazy" /></Link></div>
                                <div className="box">
                                    <h3><Link to="car-transportation">Car Transportation</Link></h3>
                                </div>
                            </div>
                            <div className="item col-6 col-md-4 col-lg-2">
                                <div className="stat__icon"><Link to="warehousing-services"><img src="images/se-5.png" width="55" height="55" alt="Hikes" loading="lazy" /></Link></div>
                                <div className="box">
                                    <h3><Link to="warehousing-services">Warehousing</Link></h3>
                                </div>
                            </div>
                            <div className="item col-6 col-md-4 col-lg-2">
                                <div className="stat__icon"><img src="images/se-6.png" width="55" height="55" alt="Hikes" loading="lazy" /></div>
                                <div className="box">
                                    <h3>Pet Relocation</h3>
                                </div>
                            </div>
                        </div>
                        <div className="btnBox text-center">
                            <Link className="btn theme-btn btn2" to="contact-us">Take 1st Step to Plan your Move</Link>
                        </div>
                    </div>
                </section>
                <section className="aboutUs section-padding">
                    <div className="container">
                        <div className="heading text-center">
                            <span className="subTitle">About</span>
                            <h2>Easy My Shift</h2>
                        </div>
                        <div className="row">
                            <div className="column col-xl-12">
                                <div className="aboutTab_list">
                                    <ul id="tabs" className="nav nav-tabs" role="tablist">
                                        <li className="nav-item"><a id="tab-A" href="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Company Overview</a></li>
                                        <li className="nav-item"><a id="tab-B" href="#pane-B" className="nav-link" data-toggle="tab" role="tab">Why Choose Us</a></li>
                                        <li className="nav-item"><a id="tab-C" href="#pane-C" className="nav-link" data-toggle="tab" role="tab">How we Work?</a></li>
                                    </ul>
                                </div>
                                <div id="content" className="tab-content" role="tablist">
                                    <div id="pane-A" className="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                                        <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
                                            <div className="card-body">
                                                <div className="imgBox" style={{ backgroundimage: 'url(images/visa-assistance.jpg)' }}></div>
                                                <div className="textBox text-justify">
                                                    <h2>Best Movers and Packers Near me</h2>
                                                    <p style={{ textalign: 'justify' }}>EasyMyShift is one of the best packers and movers near me. It is another successful origin from the Easy My Shift Packers and Movers, and it is the most trusted and verified Movers and Packers India. We are popular for our high-quality domestic relocation services. which will provide you with a complete relocation experience. Whether you are moving household items, office furniture or entrusted items, car and bike transportation&nbsp;you can safely transport the goods to the desired location by relying on us.</p>
                                                    <p style={{ textalign: 'justify' }}>Our team of professional packers and movers are highly trained and efficient in dismantling &ndash; reassembling, loading, and unloading heavy items. They are skilled in handling any type of odd-shaped and bulky items. With us, you can rest assured of a safe and trouble-free moving experience.</p>
                                                    <p style={{ textalign: 'justify' }}>We are experienced with more than <strong>15 years in this service industry</strong> and we happily shifted more than 9000 customers to their desired places all over India.&nbsp;&nbsp;We have a <strong>24x7 customer support service</strong> to help you out anytime in the day or even at night.</p>
                                                    <p style={{ textalign: 'justify' }}>We use the best material quality and special care processes to package goods: from furniture, books, and decorative pieces to pottery and glassware. The place of origin is removed and installed in your new house, including our service of fixing the furniture to the wall, so you don&rsquo;t worry and do not put your extra effort.</p>
                                                    <p style={{ textalign: 'justify' }}>Also, we try to keep our charges affordable so that our customers can avail of our quality services to relocate their household or commercial goods from anywhere&nbsp;in India. So you can shift hassle-free to your dream home at the lowest cost and highest satisfaction.</p>
                                                    <p style={{ textalign: 'justify' }}>Choose us as a Trusted and <strong>Best Movers and Packers in India</strong>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="pane-B" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
                                        <div id="collapse-B" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-B">
                                            <div className="card-body">
                                                <div className="imgBox" style={{ backgroundimage: 'url(images/visa-assistance.jpg)' }}></div>
                                                <div className="textBox">
                                                    <p>Pack for a move is very common and it can be one of the most stressful aspects of planning for Moving stuff or packing and unpacking of goods in the most efficient manner, By making the use of the most durable and strong packaging material, the safety is must.</p>
                                                    <p>Shifting to a new place is always a big challenge. The process of packing up, relocating to a new city and unpacking is anything but easy. So that hiring packer and mover is a viable and effective alternative to doing it yourself and ensure that all your belongings are safe.</p>
                                                    <h4>There are top 7 benefits of hiring professional packers and movers:-</h4>
                                                    <p>1. Efficient and safe moving—There are many Benefits of hiring  packers and movers more than the move your belongings. The services which  they  provide will make sure that your furniture, appliances, decorative items, etc are safe. They use high quality materials like cartoons, appropriate cushioning and soft wrapping polythene for packing. They pack and transport all your delicate items with extra care which is most important part of safe moving. Once reach  your destination, the crew will unload all your items and help you unpack. You can get your heavy furniture and large appliances set up right away and not need to worry about hiring more helper.</p>
                                                    <p>2. Fast services with loading and unloading process-packers and movers in jaipur rajasthan are experts and dedicated to provide worldwide quality packing and moving solution for each and every type of requirements. We take extreme care to shift your vehicle from source to destination, Other household or office goods, your cares transported separately in covered containers, under our more precise care. </p>
                                                    <p>3. Offer transit insurance-Easy My shift (Packing and moving services in jaipur) is known for providing quality relocation services and customer satisfaction every time. Including packers and mover’s service, we also offer car carrier service, loading and unloading service, warehouse service, and insurance service in rajasthan.we provide insurance for household goods or official goods services. Except few factors beyond our control like accident, fire, sabotage, riots etc. We provide you with insurance for damaged and lost goods. we give you an extra level of security for shifting your products over long distances,Moreover, it is an essential aspect of expensive shifting.</p>
                                                    <p>4 .It is budget-friendly- we as an expert team handle your precious stuff shifting professionally and provide you best and affordable home shifting at affordable price.</p>
                                                    <p>5. Warehousing- This is for different city if you wish for home reloation. Usually, such a move means that there is a time gap between when you physically leave your current house and move in to your new address in the new city. If you were to move your stuff by yourself, arranging for warehousing services can be quite a hassle. Safety is the other concern too. With professional packers and movers, all your goods will be packed into a single container that can be off-loaded and attached to a different truck. We will have our own warehousing arrangements where the container (and your belongings) will be stored safely until you are ready to receive it.</p>
                                                    <p>6. Door to door shifting service- This process starts from the dispatch of products from your warehouse to the delivery of the product to the end customer everything is done at one go, therefore you do not need to arrange for any first- mile or last-mile delivery separately.</p>
                                                    <p>7. Trained workers-We have trained workers who do all operations from loading to unloading, from packing to unpacking that are required for hassle-free shifting, we also provide you with trained workers for your shifting. You can contact them at any point and understand the status of your shipments or clarify and doubts that you may have for packing the products for dispatch.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="pane-C" className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-C">
                                        <div id="collapse-C" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-C">
                                            <div className="card-body">
                                                <div className="imgBox" style={{ backgroundimage: 'url(images/visa-assistance.jpg)' }}></div>
                                                <div className="textBox">
                                                    <h4>We follow budget-friendly and hassle free process to deliver services after receive customer order.</h4>
                                                    <ul>
                                                        <li>you will get call from our managing team to tell you about the shifting process, After booking,</li>
                                                        <li>Our shifting manager will reach at your location with the packaging trained team on scheduled date and time</li>
                                                        <li>The team member will pack your stuff by following appropriate safety protocols and ease you do not to worry.</li>
                                                        <li>Shifting manager will mark the items.</li>
                                                        <li>Shifting manager will prepare the documents along with item list which need to move</li>
                                                        <li>After documentation, loading will begin</li>
                                                        <li>Floor staff will handover a copy of the documents to you</li>
                                                        <li>Items will at reach your destination within the time slot given by you</li>
                                                        <li>Unloading team will reach along with your Stuff and deliver your goods safely at your home or office</li>
                                                    </ul>
                                                    <p>Choose EasyMyShift packers and movers for simple, effortless and hassle free shifting and moving all over Rajasthan.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="how_work section-padding dark-bg text-white">
                    <div className="container">
                        <div className="heading text-center">
                            <h2>How it Works</h2>
                        </div>
                        <div className="workSlider owl-carousel">
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon1.png" width="64" height="64" alt="pre move survey" loading="lazy" /></div>
                                <h4>Pre-Move Survey</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon2.png" width="64" height="64" alt="Dis-Assembling" loading="lazy" /></div>
                                <h4>Dis-Assembling</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon3.png" width="64" height="64" alt="Quality Packaging" loading="lazy" /></div>
                                <h4>Quality Packaging</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon4.png" width="64" height="64" alt="Careful Loading" loading="lazy" /></div>
                                <h4>Careful Loading</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon5.png" width="64" height="64" alt="Secure Transport" loading="lazy" /></div>
                                <h4>Secure Transport</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon6.png" width="64" height="64" alt="Keeping at Warehouse" loading="lazy" /></div>
                                <h4>Keeping at Warehouse</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon7.png" width="64" height="64" alt="On-time Delivery" loading="lazy" /></div>
                                <h4>On-time Delivery</h4>
                            </div>
                            <div className="item">
                                <div className="icon"><img src="images/hiw-icon8.png" width="64" height="64" alt="Careful Un-loading" loading="lazy" /></div>
                                <h4>Careful Un-loading</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ourServices">
                    <div className="service_wraper section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="imgBox col-md-6">
                                    <img src="images/service1.png" width="570" height="260" alt="packers and movers" loading="lazy" />
                                </div>
                                <div className="textBox col-md-6">
                                    <h2>Trouble-Free Instant booking on Realistic Prices</h2>
                                    <p>Without delay , get uninterrupted packing and booking budget- friendly, Value added services in moving and shifting</p>
                                    <p>Pay and Book your move from our website EasyMyShift.com.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service_wraper blueLight_bg section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="imgBox col-md-6 order-md-12">
                                    <img src="images/service2.png" width="570" height="260" alt="relocation services" loading="lazy" />
                                </div>
                                <div className="textBox col-md-6">
                                    <h2>Trained Knowledgeable Team for Packing</h2>
                                    <p>The best material Quality and Special care processes to package goods in your move.</p>
                                    <p>Our Trained Staff deliver highly efficient, timely services & also speeds up the shifting with safety.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service_wraper section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="imgBox col-md-6">
                                    <img src="images/service3.png" width="570" height="260" alt="Relocation" loading="lazy" />
                                </div>
                                <div className="textBox col-md-6">
                                    <h2>Ensure Safe-and-Sound Relocation by Covered Vehicles</h2>
                                    <p>We guarantee covered vehicles for your intra city and inter city moves.</p>
                                    <p>Your belongings are always protected from weather and rain.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service_wraper orgLight_bg section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="imgBox col-md-6 order-md-12">
                                    <img src="images/service4.png" width="570" height="260" alt="Quicker delivery" loading="lazy" />
                                </div>
                                <div className="textBox col-md-6">
                                    <h2>Quicker delivery in Short Notice</h2>
                                    <p>We are available 24/7 to you in just a click or a call.</p>
                                    <p>The consignment will reach to the final destination well before time as per our promise as quick as possible.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service_wraper section-padding">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="imgBox col-md-6">
                                    <img src="images/service5.png" width="570" height="260" alt="Risk Coverage" loading="lazy" />
                                </div>
                                <div className="textBox col-md-6">
                                    <h2>Risk Coverage while Relocation</h2>
                                    <p>On demand risk cover policy available for office and household shifting.</p>
                                    <p>We will stand by you during the entire claim process, if at all there is a need for it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ourProcess section-padding orgLight_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="box">
                                    <div className="icon">
                                        <img src="images/step-1.svg" width="120" height="165" alt="details" loading="lazy" />
                                    </div>
                                    <div className="textBox">
                                        <h3>Enter your details</h3>
                                        <p>Enter your move details with your email/phone number</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box middleBox">
                                    <div className="icon">
                                        <img src="images/step-2.svg" width="120" height="165" alt="quote" loading="lazy" />
                                    </div>
                                    <div className="textBox">
                                        <h3>Get an instant price</h3>
                                        <p>Select the items for shifting, your from/to address to get an insta quote</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box">
                                    <div className="icon">
                                        <img src="images/step-3.svg" width="120" height="165" alt="book now" loading="lazy" />
                                    </div>
                                    <div className="textBox">
                                        <h3>Book your move</h3>
                                        <p>Pay a nominal amount to confirm your booking & you are good to go</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="note text-center mt-5"><i className="fa fa-thumbs-up"></i> Yes! It’s that simple. <a href="#">Plan your move now</a></div>
                    </div>
                </section>
                <section className="we_offer section-padding">
                    <div className="container">
                        <div className="heading">
                            <h2>What We Offer - Trusted Packers and Movers</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="item">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="imgBox">
                                                <img className="img-fluid" src="images/young-couple-hiding-their-face-sitting.jpg" width="354" height="254" alt="Home/Domestic Shifting" loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 textBox">
                                            <h3>Home/Domestic Shifting</h3>
                                            <p>Easy My shift is a fast growing company in relocating with presence in every corner of Rajasthan. We packers and movers deal into household, Offices, industial packing and moving, we also deal with car and office shifting. The shifting is done with a hassle-free, quick and safe without inconvenience.</p>
                                            <div className="btnBox mt-3"><Link className="btn readMore" to="home-domestic-shifting">Read More..</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="imgBox">
                                                <img className="img-fluid" src="images/office-shifting.jpg" width="354" height="254" alt="Office/Commercial Shifting" loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 textBox">
                                            <h3>Office/Commercial Shifting</h3>
                                            <p>EasyMyShift packers and movers is well mannered and fully professional to provide equipment, excellent warehouse and materials and fully functional facilities for relocation services to corporate employees and their transferring families all over rajasthan. We provide highest quality and budget friendly office movement services to our customer.</p>
                                            <div className="btnBox mt-3"><Link className="btn readMore" to="office-commercial-shifting">Read More..</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="imgBox">
                                                <img className="img-fluid" src="images/moto-bikes-bg.jpg" width="354" height="254" alt="Bike Transportation" loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 textBox">
                                            <h3>Bike Transportation</h3>
                                            <p>We offer the most trustable and efficient car and bike carrier service. We take care the car and bike with utmost care so that it is free of any scar. Strongly believe in Safety, So it is our first priority while relocating your car or bike from one place to another. We are proud to guarantee a safe-and-sound transportation of your cars and bike.</p>
                                            <div className="btnBox mt-3"><Link className="btn readMore" to="bike-transportation">Read More..</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="imgBox">
                                                <img className="img-fluid" src="images/car-transport.jpg" width="354" height="254" alt="Car Transportation" loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 textBox">
                                            <h3>Car Transportation</h3>
                                            <p>We offer the most trustable and efficient car and bike carrier service. We take care the car with utmost care so that it is free of any scar. We take the help of our various car trailers or trucks so that we can transport your cars all over Rajasthan on a door-to-door basis. Our trained team members keep the trailers and containerized trucks...</p>
                                            <div className="btnBox mt-3"><Link className="btn readMore" to="car-transportation">Read More..</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="imgBox">
                                                <img className="img-fluid" src="images/warehousing-services.jpg" width="354" height="254" alt="Warehousing Services" loading="lazy" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 textBox">
                                            <h3>Warehousing Services</h3>
                                            <p>EasyMyShift Packers and Movers has client oriented and specifically designed warehousing and storage facility and laid out floor plan with fire prevention construction properly, and provided with high levels of security with parking facilities for all kinds of vehicles, including container trucks and trailers.</p>
                                            <div className="btnBox mt-3"><Link className="btn readMore" to="warehousing-services">Read More..</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="talk_toUs formBox">
                                    <h3>Get <strong>FREE QUOTES</strong> in a minute</h3>
                                    <form method="POST" encType="multipart/form-data" action="https://formsubmit.co/3ce499c88c3b70c3f74752c5d59fd841" >
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                required
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="Enter your full name"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <span className="code form-control">IN +91</span>
                                                <input
                                                    className="form-control"
                                                    type="tel"
                                                    name="number"
                                                    required
                                                    value={number}
                                                    onChange={(e) => setNumber(e.target.value)}
                                                    placeholder="Enter your Mobile no"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                required
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter your Email"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="moving_from"
                                                value={movingFrom}
                                                onChange={(e) => handleInputChange(e.target.value, setMovingFrom, setSuggestionsFrom)}
                                                placeholder="Moving From"
                                            />
                                            {suggestionsFrom.length > 0 && (
                                                <ul className="suggestions-list">
                                                    {suggestionsFrom.map((suggestion, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleSuggestionClick(suggestion, setMovingFrom, setSuggestionsFrom)}
                                                        >
                                                            {suggestion}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="moving_to"
                                                value={movingTo}
                                                onChange={(e) => handleInputChange(e.target.value, setMovingTo, setSuggestionsTo)}
                                                placeholder="Moving To"
                                            />
                                            {suggestionsTo.length > 0 && (
                                                <ul className="suggestions-list">
                                                    {suggestionsTo.map((suggestion, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => handleSuggestionClick(suggestion, setMovingTo, setSuggestionsTo)}
                                                        >
                                                            {suggestion}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="moving_date"
                                                value={movingDate}
                                                onChange={(e) => setMovingDate(e.target.value)}
                                                placeholder="Moving Date"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <textarea
                                                className="form-control"
                                                placeholder="Move Detail"
                                                name="moving_detail"
                                                required
                                                value={movingDetail}
                                                onChange={(e) => setMovingDetail(e.target.value)}
                                            ></textarea>
                                        </div>
                                        <div className="btnBox mt-4">
                                            <button className="btn theme-btn submitBtn">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ielts_coaching section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 pr-lg-5">
                                <div className="imgBox">
                                    <img className="img-fluid fade-in" src="images/reviews-img.png" width="538" height="600" alt="reviews" loading="lazy" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="heading">
                                    <h5>EasyMyShift</h5>
                                    <h2>India’s Best Packers and Movers</h2>
                                </div>
                                <div className="learnerReviews">
                                    <h3>Google Reviews</h3>
                                    <div className="review-box mt-4">
                                        <div className="row align-items-center">
                                            <div className="col-lg-auto">
                                                <div className="review-box-score">
                                                    <div className="review-score"><strong>5</strong>/5</div>
                                                </div>
                                            </div>
                                            <div className="col-lg">
                                                <div className="review-score-text">
                                                    <span className="stars"><i className="fa fa-star active"></i><i className="fa fa-star active"></i><i className="fa fa-star active"></i><i className="fa fa-star active"></i><i className="fa fa-star active"></i></span>
                                                    Based on <span>353 reviews</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btnBox mt-5">
                                    <a className="btn theme-btn left-icon" href="tel:09812009192"><i className="flaticon flaticon-telephone"></i> Book a free call</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ourFaq section-padding">
                    <div className="container">
                        <div className="heading text-center">
                            <span className="subTitle">Our FAQ's</span>
                            <h3>Frequently Asked Questions</h3>
                        </div>
                        <div id="sAccordion">
                            <div className="faq_box">
                                <h4 className="title" data-toggle="collapse" data-target="#sColumn1" aria-expanded="true">How much packers and movers will cost to pack and move your vehicle from one location to another?</h4>
                                <div id="sColumn1" className="column collapse show" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>The Cost will depend on the distance of the transportation, the number of items or cars being moved, and also your demand for insurance during transportation. And the season and timing of relocation matter a lot, They also ask for higher fees during summer and peak seasons. Hence, you must avoid weekends, month ends, or even month beginnings for relocation.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn2" aria-expanded="false">When should I start looking for packers and movers?</h4>
                                <div id="sColumn2" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>It all depends on you, when and where need to shift. If it is a local transfer within the same city, you should start looking for them at least 4-8 days in advance. Moreover, you need to search for movers and packers 3-4 weeks before moving out of the city. If the move date should be the end of the month, the search should also be started earlier.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn3" aria-expanded="false">How I can reduce my shifting charges with packers and movers?</h4>
                                <div id="sColumn3" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>The quantity and type of goods you want to transport are the main factors that affect the cost of relocation from one place to another. If the number of items or projects can be reduced by sorting out, then costs can be reduced.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn4" aria-expanded="false">What are the things packers and movers will take care off while moving?</h4>
                                <div id="sColumn4" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>From start to finish the task, all processes will be properly handled trained team members. It includes the sorting of sundries, packaging, loading and unloading everything, until the final placement of the items. When the family relocation outside the city is involved, the cost of unpacking and other services is not included. However, the inclusion is on-demand which could be the pocket friendly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn5" aria-expanded="false">How long does it take to pack and unpack items in my house?</h4>
                                <div id="sColumn5" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>The time required for packaging and loading depends on the number of items to be transported within the city and out of the city. However, it usually takes about 5-6 hours to pack and load things.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn6" aria-expanded="false">What are my responsibilities during the moving process need to take care?</h4>
                                <div id="sColumn6" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>During the Shifting, you don’t have to worry too much. However, you will have to provide various documents at the time of relocation to the shift manager. It is recommended that you have an in-depth discussion with our on-site personnel.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn7" aria-expanded="false">How much do packers and movers charge for transportation?</h4>
                                <div id="sColumn7" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>Professional packers and movers charge approximately Rs 3,000 to Rs 30,000 for local shifting and Rs 10,000 to Rs 70,000 for domestic or intercity relocation requirements.it is just a figure, depends on the number of items.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn8" aria-expanded="false">What are the GST rates?</h4>
                                <div id="sColumn8" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>GST rate applicable on packers and movers will be 18% for complete relocation services that include packing, loading, unloading, transportation and insurance. If you use only transport (logistic) service of packers and movers then GST rate will be applied at the rate of 5% only.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn9" aria-expanded="false">What is the cost of moving insurance by packers and movers?</h4>
                                <div id="sColumn9" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>It is approx. 3% with taxes by packers and movers for shifting of The cost of moving insurance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn10" aria-expanded="false">Do packers and movers also provide door-to-door shifting services?</h4>
                                <div id="sColumn10" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>Yes. Our most valuable service is door-to-door shifting service. This means they will pack your belongings at your door, load them onto the truck, offload the truck after reaching the destination, and unpack everything at your new place. They may also help to rearrange your belongings.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn11" aria-expanded="false">What are the services offered by packers and movers for shifting?</h4>
                                <div id="sColumn11" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>Professional packers and movers will make the process a whole lot easier with their services. Usually, they provide packing and moving solutions for a  complete house or office shifting service includes a series of services, such as packing, furniture dismantling & assembly, packing, loading, transporting, unloading, and unpacking.</p>
                                        <p><strong>A few services are-</strong></p>
                                        <ol>
                                            <li>Household shifting</li>
                                            <li>Warehousing & storage</li>
                                            <li>Office shifting or corporate relocation</li>
                                            <li>Commercial & industrial goods shifting</li>
                                            <li>Bike shifting from one city to another</li>
                                            <li>Car carrier & transportation from one city to another</li>
                                            <li>Domestic relocation services</li>
                                            <li>Local shifting services</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn12" aria-expanded="false">What kind of packing materials will be used by packers and movers to pack my belongings?</h4>
                                <div id="sColumn12" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>Packers and movers will pack your belonging using good quality packing supplies and sturdy boxes. If your shifting is scheduled in the rainy season then they will provide a waterproof packing service with extra care.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="faq_box">
                                <h4 className="title collapsed" data-toggle="collapse" data-target="#sColumn13" aria-expanded="false">What is a pre-move survey?</h4>
                                <div id="sColumn13" className="column collapse" data-parent="#sAccordion" >
                                    <div className="innerBox">
                                        <p>Packers and movers to analyze the shifting circumstances and prepared the list of items that need to be shifted by a pre-move survey.Pre-move survey also helps in giving you the most accurate price estimate.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





















            </>
        );
    }

    return <MyForm />;
}