import React from "react";
import { Link } from "react-router-dom";

export default function btm_1() {
  return (
    <>
      <header id="header" className="header">
				<div className="navbarMenu">
					<div className="container">
						<div className="row align-items-center">
							<div className="selectCountry dropdown col-6 col-lg-auto order-lg-2">
								<button className="toggleBtn dropdown-toggle btn theme-btn btn2" data-toggle="dropdown" aria-expanded="false">Top Cities</button>
								<ul className="dropdown-menu coursesList">
									<li><Link to="packers-and-movers-in-jaipur">Jaipur</Link></li>
									<li><Link to="packers-and-movers-in-nagpur">Nagpur</Link></li>
									<li><Link to="packers-and-movers-in-ahmedabad">Ahmedabad</Link></li>
									<li><Link to="packers-and-movers-in-jodhpur">Jodhpur</Link></li>
									<li><Link to="packers-and-movers-in-goa">Goa</Link></li>
									<li><Link to="packers-and-movers-in-udaipur">Udaipur</Link></li>
								</ul>
							</div>
							<div className="rightAction col-6 col-lg-auto btnBox order-lg-4 text-right">
								<ul className="right-nav list-unstyled inline">
									<li><a className="btn theme-btn" href="tel:09812009192"><i className="fa fa-phone"></i> 09812009192</a></li>
								</ul>
							</div>
							<div className="headBar col-12 col-lg-auto order-lg-1">
								<Link className="navbar-brand" to="../">
									<img src="images/logo.png" alt="logo" width="112" height="40" loading="lazy" />
								</Link>
								<div className="head_callBtn"><a className="btn theme-btn" href="tel:09812009192"><i className="fa fa-phone"></i>09812009192</a></div>
								<button className="openNav d-lg-none" type="submit" aria-label="menu"><span className="navBar"></span></button>
								
							</div>
							
							<div className="col-lg-auto ml-auto nav-col order-lg-3">
								<nav id="nav">
									<button className="closeNav d-lg-none">&times;</button>
									<ul className="menuItem list-unstyled inline">
									<li className="nav-item"><Link className="nav-link" to="../">Home</Link></li>
										<li className="nav-item"><Link className="nav-link" to="about">About</Link></li>
										{/* <li className="nav-item"><a className="nav-link" href="story/web-stories/web-stories.html">Stories</a></li> */}
										<li className="nav-item"><Link className="nav-link" to="contact-us">Contact</Link></li>
										{/* <li className="nav-item"><a className="nav-link" href="blog.htm">Blog</a></li> */}
									</ul>
								</nav>
							</div>
							
						</div>
					</div>
				</div>
			</header>
    </>
  );
}
