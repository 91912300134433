import React from "react";
import { Link } from "react-router-dom";

export default function btm_2() {
  return (
    <>
      
      <div id="whatOffer" className="column">
										<div className="heading text-center">
											<h2>What We Offer</h2>
										</div>
										<div className="offer_list">
											<div className="item">
												<div className="row align-items-center">
													<div className="col-md-4">
														<div className="imgBox">
															<img className="img-fluid" src="../images/young-couple-hiding-their-face-sitting.jpg" />
														</div>
													</div>
													<div className="col-md-8 textBox">
														<h3>Home/Domestic Shifting</h3>
														<p>Easy My shift is a fast growing company in relocating with presence in every corner of
														Rajasthan. We packers and movers deal into household, Offices, industial packing and moving, we also deal with car and office shifting. The shifting is done with a hassle-free, quick and safe without inconvenience.</p>
														<div className="btnBox mt-3"><Link className="btn readMore" to="../home-domestic-shifting">Read More..</Link></div>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="row align-items-center">
													<div className="col-md-4 order-md-12">
														<div className="imgBox">
															<img className="img-fluid" src="../images/office-shifting.jpg" />
														</div>
													</div>
													<div className="col-md-8 textBox">
														<h3>Office/Commercial Shifting</h3>
														<p>EasyMyShift packers and movers is well mannered and fully professional to provide
															equipment, excellent warehouse and materials and fully functional facilities for
														relocation services to corporate employees and their transferring families all over rajasthan. We provide highest quality and budget friendly office movement services to our customer.</p>
														<div className="btnBox mt-3"><Link className="btn readMore" to="../office-commercial-shifting">Read More..</Link></div>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="row align-items-center">
													<div className="col-md-4">
														<div className="imgBox">
															<img className="img-fluid" src="../images/moto-bikes-bg.jpg" />
														</div>
													</div>
													<div className="col-md-8 textBox">
														<h3>Bike Transportation</h3>
														<p>We offer the most trustable and efficient car and bike carrier service. We take care the car and bike with utmost care so that it is free of any scar. Strongly believe in Safety, So it is our first priority while relocating your car or bike from one place to another. We are proud to guarantee a safe-and-sound transportation of your cars and bike.</p>
														<div className="btnBox mt-3"><Link className="btn readMore" to="../bike-transportation">Read More..</Link></div>
													</div>
												</div>
											</div>
											<div className="item">
												<div className="row align-items-center">
													<div className="col-md-4 order-md-12">
														<div className="imgBox">
															<img className="img-fluid" src="../images/car-transport.jpg" />
														</div>
													</div>
													<div className="col-md-8 textBox">
														<h3>Car Transportation</h3>
														<p>We offer the most trustable and efficient car and bike carrier service. We take care the car with utmost care so that it is free of any scar. We take the help of our various car trailers or trucks so that we can transport your cars all over
														Rajasthan on a door-to-door basis. Our trained team members keep the trailers and containerized trucks...</p>
														<div className="btnBox mt-3"><Link className="btn readMore" to="../car-transportation">Read More..</Link></div>
													</div>
												</div>
											</div>
											<div className="item"> 
												<div className="row align-items-center">
													<div className="col-md-4">
														<div className="imgBox">
															<img className="img-fluid" src="../images/warehousing-services.jpg" />
														</div>
													</div>
													<div className="col-md-8 textBox">
														<h3>Warehousing Services</h3>
														<p>EasyMyShift Packers and Movers has client oriented and specifically designed
														warehousing and storage facility and laid out floor plan with fire prevention construction properly, and provided with high levels of security with parking facilities for all kinds of vehicles, including container trucks and trailers.</p>
														<div className="btnBox mt-3"><Link className="btn readMore" to="../warehousing-services">Read More..</Link></div>
													</div>
												</div>
											</div>
										</div>
									</div>
      
    </>
  );
}
