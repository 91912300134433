import React from "react";

export default function btm_1() {
  return (
    <>
      <section
        className="inner_banner text-white"
        style={{ backgroundImage: "url(../images/form-bg.jpg)" }}
      >
        <div className="container">
          <div className="heading text-center">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </section>
      <div className="about_wraper pt-1 pb-5">
        <div className="container">
          <div className="content column_box">
            <div className="heading">
              <h2>www.example.com respects your privacy.</h2>
            </div>
            <p>
              www.example.com understands how important your personal
              information is to you and this privacy policy explains how we
              gather personal information, and how we maintain, use, disclose
              and deal with personal information we accumulate from individuals
              in the course of our business.
            </p>
            <p>
              You are advised to please read this privacy policy prudently. By
              accessing the services provided by www.example.com on our website
              www.example.com you agree to the collection and use of your
              data/information by www.example.com in the manner provided in this
              privacy policy. This privacy policy applies to the website
              www.example.com and all products and services offered by
              www.example.com. This Policy does not apply to websites or
              services that are not owned by us and are operated by third
              parties. This policy applies to all users accessing our website
              and may be modified from time to time.
            </p>
            <p>
              We encourage you to review the Policy whenever you access the
              Services or otherwise interact with us to stay informed about our
              information practices and the ways you can help protect your
              privacy.
            </p>
            <h2>Personal Information</h2>
            <p>
              All personal information that www.example.com accumulates is
              collected in a fair and non-intrusive manner, with your consent,
              in order to be able to meet any orders, provide our services
              and/or provide any assistance you have requested.
            </p>
            <p>
              Personal information means information or an opinion about you
              from which your identity is apparent or can reasonably be
              ascertained. This is whether the information or opinion is true or
              not and whether it is recorded in material format or not.
            </p>
            <h2>Why This Privacy Policy</h2>
            <ul>
              <li>We want you to feel comfortable using our website.</li>
              <li>
                We want you to feel secure submitting information to this
                website.
              </li>
              <li>
                We want you to contact us with your questions or concerns about
                privacy on this website.
              </li>
              <li>
                We want you to know that by using our website you are consenting
                to the collection of certain data.
              </li>
            </ul>
            <h2>Information We Collect</h2>
            <p>
              We at www.example.com collect information you provide directly to
              us. We will only collect personal information from you that is
              necessary for us to be able to meet any orders, provide our
              services and/or provide any assistance you have requested. We may
              collection personal information from you such as your name, email
              id, city, address, mobile number, telephone number, gender, and
              credit/debit card details (if you making online payments). We at
              www.example.com may also collect information such as about the
              pages you visit/access, the links you click on www.example.com,
              and the number of time you access the page. We do not collect
              information about your political, philosophical, religious beliefs
              or sexual preferences.
            </p>
            <h2>Use of Information</h2>
            <p>
              We at www.example.com use your information and/or personal details
              for the purpose for which it was provided to us, other related
              purposes and as permitted or required by law. We use personal
              information to help us provided personalize features, improve
              services, tailor our websites to your interest, provide services
              requested by you, conduct survey, and get into touch with you when
              necessary or respond to your enquiry. We may use your contact
              information internally to direct our efforts for product
              improvement, contact you as a survey respondent, notify you if you
              win a contest, to notify you of upcoming events and/or promotions
              by us and/or our partnered packers and movers; and send you
              promotional offer information.
            </p>
            <h2>Information We Share</h2>
            <p>
              www.example.com will not use or store your financial information
              for any purpose other than to complete a transaction with you. We
              at www.example.com do not rent, sell or share your personal
              information and we will not disclose any of your personally
              identifiable information to third parties unless:
            </p>
            <ul>
              <li>
                To provide products or services you have requested with us.
              </li>
              <li>
                To help investigate, prevent or take action regarding unlawful
                and illegal activities, suspected fraud, potential threat to the
                safety or security of any person, violations of
                www.example.com’s terms of use or to defend against legal
                claims.
              </li>
              <li>To bill you for good and services.</li>
              <li>
                As required by law or Government/Statutory Authority or to
                comply with summons/subpoena or any legal process.
              </li>
              <li>
                When we believe in good faith that disclosure is necessary to
                protect our rights, protect your safety or the safety of others,
                investigate fraud, or respond to a government request.
              </li>
            </ul>
			<h2>Your Choices</h2>
			<p>You can review and change personal information related to your use of our website (such as your name and contact information). You may change your interests at any time and may opt-in or opt-out of any marketing / promotional / newsletters mailings.</p>
			<h2>Policy updates</h2>
			<p>We at www.example.com may update this privacy policy to reflect changes to our information practices. We reserve the right to change or update this policy at any time at our discretion and by placing a prominent notice on our website. Such changes shall be effective immediately upon posting to this site.</p>
          <h2>Contacting Us</h2>
		  <p>If you have any question about this policy or want to make choices about receiving promotional communications, to update your personal information or to place an order, you can contact us at our contact Number:</p>
		  </div>

          <section className="learnerSupport destinations column_box mt-4">
            <div className="column">
              <div className="heading text-center">
                <span className="subTitle">
                  For queries, feedback &amp; assistance
                </span>
                <h2>EasyMyShift Helpline Support</h2>
                <p>(7 AM - 9 PM)</p>
              </div>
              <div className="row justify-content-center num_detail">
                <div className="col-md-5">
                  <div className="box text-center">
                    <h3>For Domestic/Commercial Moving</h3>
                    <a className="btn theme-btn" href="tel:09812009192">
                      <i className="fa fa-phone"></i> +91 9812009192
                    </a>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="box text-center">
                    <h3>For Bike/Car Transport</h3>
                    <a className="btn theme-btn" href="tel:09812009192">
                      <i className="fa fa-phone"></i> +91 9812009192
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
