import React from "react";

export default function btm_1() {
    return (
        <>

            <section className="inner_banner text-white" style={{ backgroundImage: 'url(../images/form-bg.jpg)' }}>
                <div className="container">
                    <div className="heading text-center"><h1>Contact Us</h1></div>
                </div>
            </section>
            <section className="contact_us talk_toUs_wraper pb-5">
                <div className="container">
                    <form method="POST"  action="https://formsubmit.co/3ce499c88c3b70c3f74752c5d59fd841">
                        <div className="talk_toUs formBox">
                            <h3>Get <strong>FREE QUOTES</strong> in a minute</h3>
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="Enter your full name" />
                            </div>
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <div className="input-group">
                                        <span className="code form-control"><img className="mr-2" src="images/map-icon/india-flag.png" width="22" alt="India" /> +91</span>
                                        <input className="form-control" type="tel" placeholder="Enter your Mobile no" />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group">
                                    <input className="form-control" type="email" placeholder="Enter your Email" />
                                </div>
                                <div className="col-md-4 form-group">
                                    <input className="form-control" type="text" placeholder="Moving From" />
                                </div>
                                <div className="col-md-4 form-group">
                                    <input className="form-control" type="text" placeholder="Moving To" />
                                </div>
                                <div className="col-md-4 form-group">
                                    <input className="form-control" type="date" placeholder="Moving Date" />
                                </div>
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" placeholder="Move Detail" rows="5"></textarea>
                            </div>
                            <div className="btnBox mt-4">
                                <button className="btn theme-btn submitBtn">Submit</button>
                            </div>
                        </div>
                    </form>
                    <div className="connect_us row">
                        <div className="col-12 heading text-center">
                            <span className="subTitle">For queries, feedback &amp; assistance</span>
                            <h2>EasyMyShift Helpline Support</h2>
                            <p>(7 AM - 9 PM)</p>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <i className="fa fa-whatsapp"></i>
                                <div className="text">
                                    <h4>Chat With Whatsapp</h4>
                                    <a href="https://wa.me/919812009192">+91-9812009192</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <i className="fa fa-phone"></i>
                                <div className="text">
                                    <h4>Call Us Now</h4>
                                    <a href="tel:09812009192">+91 9812009192</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box">
                                <i className="fa fa-envelope"></i>
                                <div className="text">
                                    <h4>Email Us</h4>
                                    <a href="mailto:info@EasyMyShift.com">info@EasyMyShift.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map_area">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13728.61209412361!2d76.8196671!3d30.6578192!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390feb51318c4fcd%3A0x49c6fe5ba067588d!2sEasy%20My%20Shift!5e0!3m2!1sen!2sin!4v1724590971208!5m2!1sen!2sin" width="100%" height="450px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>

        </>
    );
}
