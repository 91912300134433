import React from "react";
import { Link } from "react-router-dom";

export default function btm_2() {
  return (
    <>
      
      <div className="column cities_covered">
										<div className="heading">
											<h3>Other Cities Covered by EasyMyShift</h3>
										</div>
										<div className="processItem item_list">
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-udaipur"><img src="../images/udaipur.png" width="50" height="50" alt="udaipur" /></Link></div>
												<div className="box">
													<h3><Link to="../packers-and-movers-in-udaipur">Udaipur</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-jaipur"><img src="../images/jaipur.png" width="50" height="50" alt="jaipur" /></Link></div> 
												<div className="box">
													<h3><Link to="../packers-and-movers-in-jaipur">Jaipur</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-nagpur"><img src="../images/nagpur.png" width="50" height="50" alt="nagpur" /></Link></div> 
												<div className="box">
													<h3><Link to="../packers-and-movers-in-nagpur">Nagpur</Link></h3>
												</div>
											</div>
											
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-goa"><img src="../images/goa.png" width="50" height="50" alt="Goa" /></Link></div>
												<div className="box">
													<h3><Link to="../packers-and-movers-in-goa">Goa</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-ahmedabad"><img src="../images/ahmedabad.png" width="50" height="50" alt="Ahmedabad" /></Link></div>
												<div className="box">
													<h3><Link to="../packers-and-movers-in-ahmedabad">Ahmedabad</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-jodhpur"><img src="../images/jodhpur.png" width="50" height="50" alt="jodhpur" /></Link></div>
												<div className="box">
													<h3><Link to="../packers-and-movers-in-jodhpur">Jodhpur</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-mumbai"><img src="../images/mumbai.png" width="50" height="50" alt="Mumbai" /></Link></div> 
												<div className="box">
													<h3><Link to="../packers-and-movers-in-mumbai">Mumbai</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-bhopal"><img src="../images/bhopal.png" width="50" height="50" alt="Bhopal" /></Link></div> 
												<div className="box">
													<h3><Link to="../packers-and-movers-in-bhopal">Bhopal</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-lucknow"><img src="../images/lucknow.png" width="50" height="50" alt="lucknow" /></Link></div> 
												<div className="box">
													<h3><Link to="../packers-and-movers-in-lucknow">Lucknow</Link></h3>
												</div>
											</div>
											<div className="item">
												<div className="stat__icon"><Link to="../packers-and-movers-in-faridabad"><img src="../images/faridabad.png" width="50" height="50" alt="faridabad" /></Link></div> 
												<div className="box">
													<h3><Link to="../packers-and-movers-in-faridabad">Faridabad</Link></h3>
												</div>
											</div>
										</div>
									</div>
      
    </>
  );
}
