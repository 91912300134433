import React, { useState } from "react";
import "./suggestions.css"; // Import your styles

export default function btm_2() {
  function MyForm() {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [movingFrom, setMovingFrom] = useState('');
    const [movingTo, setMovingTo] = useState('');
    const [movingDate, setMovingDate] = useState('');
    const [movingDetail, setMovingDetail] = useState('');

    const [suggestionsFrom, setSuggestionsFrom] = useState([]);
    const [suggestionsTo, setSuggestionsTo] = useState([]);

    const fetchSuggestions = async (query, setSuggestions) => {
      if (query.length > 2) {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?city=${query}&countrycodes=IN&format=json&addressdetails=1&limit=5`
        );
        const data = await response.json();
        setSuggestions(
          data.map((item) => item.display_name)
        );
      } else {
        setSuggestions([]);
      }
    };

    const handleInputChange = (value, setValue, setSuggestions) => {
      setValue(value);
      fetchSuggestions(value, setSuggestions);
    };

    const removeHindiWords = (text) => {
      // Regular expression to match Hindi characters
      const hindiRegex = /[\u0900-\u097F]/g;
      
      // Remove Hindi characters
      let cleanedText = text.replace(hindiRegex, '').trim();
      
      // Remove extra spaces and clean up the result
      cleanedText = cleanedText
        .split(',')
        .map(item => item.trim()) // Remove spaces around each part
        .filter(item => item !== '') // Remove empty items
        .join(', '); // Join back with a single comma and space

      return cleanedText;
    };

    const handleSuggestionClick = (suggestion, setValue, setSuggestions) => {
      const cleanedSuggestion = removeHindiWords(suggestion);
      setValue(cleanedSuggestion);
      setSuggestions([]);
    };

    return (
      <div className="talk_toUs formBox d-block">
        <h3>Get FREE QUOTES in a minute</h3>
        <form method="POST"  action="https://formsubmit.co/3ce499c88c3b70c3f74752c5d59fd841">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your full name"
            />
          </div>
          <div className="form-group">
            <div className="input-group">
              <span className="code form-control">IN +91</span>
              <input
                className="form-control"
                type="tel"
                name="number"
                required
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter your Mobile no"
              />
            </div>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your Email"
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="moving_from"
              value={movingFrom}
              onChange={(e) => handleInputChange(e.target.value, setMovingFrom, setSuggestionsFrom)}
              placeholder="Moving From"
            />
            {suggestionsFrom.length > 0 && (
              <ul className="suggestions-list">
                {suggestionsFrom.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion, setMovingFrom, setSuggestionsFrom)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="moving_to"
              value={movingTo}
              onChange={(e) => handleInputChange(e.target.value, setMovingTo, setSuggestionsTo)}
              placeholder="Moving To"
            />
            {suggestionsTo.length > 0 && (
              <ul className="suggestions-list">
                {suggestionsTo.map((suggestion, index) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion, setMovingTo, setSuggestionsTo)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              type="date"
              name="moving_date"
              value={movingDate}
              onChange={(e) => setMovingDate(e.target.value)}
              placeholder="Moving Date"
            />
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Enter your Goods Details"
              name="moving_detail"
              required
              value={movingDetail}
              onChange={(e) => setMovingDetail(e.target.value)}
            ></textarea>
          </div>
          <div className="btnBox mt-4">
            <button className="btn theme-btn submitBtn">Submit</button>
          </div>
        </form>
      </div>
    );
  }

  return <MyForm />;
}
