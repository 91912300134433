import React from "react";

export default function btm_1() {
  return (
    <>
      <section
        className="inner_banner text-white"
        style={{ backgroundImage: "url(../images/form-bg.jpg)" }}
      >
        <div className="container">
          <div className="heading text-center">
            <h1>Terms and Conditions</h1>
          </div>
        </div>
      </section>
      <div className="about_wraper pt-1 pb-5">
        <div className="container">
          <div className="content column_box">
            <div className="heading">
              <h2>Terms And Condition For Easy My Shift</h2>
            </div>
            <p>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Easy My Shift's relationship with you in relation to this website....</p>
            <ul>
              <li>We would request you in part load pay 100% advance in loading point.</li>
              <li>Please keep your Cash/Jewellery in your custody. Company would not be liable for any mis happening.</li>
              <li>Paying tips at the drop off location will be customer’s choice and cannot be claimed afterwards.</li>
              <li>The above quotes do not include any dismantling (Carpenter Work) and fittings of electrical, electronic appliances. Those charges would be extra.</li>
            <li>The packing materials are the property of the Company and will be taken back on the same day of unloading, otherwise, rent will be charged as per the company's policy.</li>
			</ul>

            <h2>Shipping & Delivery Policy</h2>
			<p>This is another policy that must be clearly stated on website. Include the delivery time frame and any restrictions that merchant might have on delivering the products but atleast delivery time 7 days minimum. List on the website all export restrictions that merchant is aware of for the products that are sold online. A company must ship your order within the time stated in its ad or give an option notice to the customers otherwise.</p>
            <h2>Website privacy</h2>
			<p>This privacy policy sets out how Easy My Shift uses and protects any information that you give Easy My Shift when you use this website. Easy My Shift is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement..</p>
			<h2>Website privacy</h2>
			<p>A disclaimer may specify mutually-agreed and privately-arranged terms and conditions as part of a contract ; or may specify warnings or expectations to the general public (or some other class of persons) in order to fulfill a duty of care owed to prevent unreasonable risk of harm or injury. Some disclaimers are intended to limit exposure to damages after harm or injury has already been suffered. Additionally, some kinds of disclaimers may represent a voluntary waiver of a right or obligation that may be owed to the disclaimant...</p>
			<h2>Cancellation & Refund Policy</h2>
			<p>Easy My Shift believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy refund/replacement can be made within 7days if the customer establishes that the quality of product delivered is not good....</p>

			<ul>
              <li>Cancellations will be considered only if the request is made within 72 hours of placing an order. However, the cancellation request will not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them..</li>
              <li>There is no cancellation of orders placed under the Same Day Delivery category..</li>
			  <li>No cancellations are entertained for those products that the Easy My Shift marketing team has obtained on special occasions like Pongal, Diwali, Valentine’s Day etc. These are limited occasion offers and therefore cancellations are not possible.</li>
			  <li>Easy My Shift does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.</li>
			  <li>In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 24 hours of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.</li>
            </ul>
          </div>

          <section className="learnerSupport destinations column_box mt-4">
            <div className="column">
              <div className="heading text-center">
                <span className="subTitle">
                  For queries, feedback &amp; assistance
                </span>
                <h2>EasyMyShift Helpline Support</h2>
                <p>(7 AM - 9 PM)</p>
              </div>
              <div className="row justify-content-center num_detail">
                <div className="col-md-5">
                  <div className="box text-center">
                    <h3>For Domestic/Commercial Moving</h3>
                    <a className="btn theme-btn" href="tel:09812009192">
                      <i className="fa fa-phone"></i> +91 9812009192
                    </a>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="box text-center">
                    <h3>For Bike/Car Transport</h3>
                    <a className="btn theme-btn" href="tel:09812009192">
                      <i className="fa fa-phone"></i> +91 9812009192
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
